import React, { useState } from 'react';
import { Brain, Eye, MessageSquare } from 'lucide-react';
import './AILearningGame.css';
import { Link } from 'react-router-dom';

const AILearningGame = () => {
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const [completedConcepts, setCompletedConcepts] = useState([]);
  const [selectedConcept, setSelectedConcept] = useState(null);
  const [gamePhase, setGamePhase] = useState('selection');
  const [userInput, setUserInput] = useState('');
  const [messages, setMessages] = useState(['Welcome to AI Explorer! Choose a concept to begin your journey.']);
  const [score, setScore] = useState(0);
  const [currentGame, setCurrentGame] = useState(null);
  

  const handleConceptSelect = (concept) => {
    setSelectedConcept(concept);
    setGamePhase('content');
    setCurrentContentIndex(0);
    setMessages([`Welcome to ${aiConcepts[concept].title}! Let's explore this concept.`]);
  };

  const handleNextContent = () => {
    const concept = aiConcepts[selectedConcept];
    if (currentContentIndex < concept.content.length - 1) {
      setCurrentContentIndex(currentContentIndex + 1);
    } else {
      setGamePhase('game');
      setCurrentGame(concept.games[0]);
      setMessages([concept.games[0].challenge]);
    }
  };

  const handlePreviousContent = () => {
    if (currentContentIndex > 0) {
      setCurrentContentIndex(currentContentIndex - 1);
    }
  };

  const handleBackToSelection = () => {
    setGamePhase('selection');
    setSelectedConcept(null);
    setCurrentGame(null);
    setCurrentContentIndex(0);
  };

  const handleCommand = () => {
    if (!userInput.trim()) return;
    
    const input = userInput.trim().toLowerCase();
    setMessages(prev => [...prev, `You: ${userInput}`]);

    if (currentGame) {
      if (input === currentGame.answer.toLowerCase()) {
        setScore(prevScore => prevScore + 10);
        setMessages(prev => [...prev, "✨ Correct! +10 points"]);
        if (!completedConcepts.includes(selectedConcept)) {
          setCompletedConcepts([...completedConcepts, selectedConcept]);
        }
        setTimeout(() => {
          handleBackToSelection();
        }, 2000);
      } else if (input === 'hint') {
        setMessages(prev => [...prev, `💡 Hint: ${currentGame.hint}`]);
      } else {
        setMessages(prev => [...prev, "Try again or type 'hint' for help!"]);
      }
    }
    setUserInput('');
  };

  const renderHeader = () => (
    <div>
    
      <div className="game-explorer-header">
        <h1 className="game-explorer-title">AI Explorer</h1>
        <p className="game-explorer-subtitle">Master AI concepts through interactive learning</p>
        <div className="score-display">
          <span className="score-text">Score: {score}</span>
        </div>
      </div>
    </div>
  );

  const renderConceptSelection = () => (
    <div className="concepts-grid">
      {Object.entries(aiConcepts).map(([key, concept]) => (
        <div 
          key={key}
          className={`concept-card ${concept.theme} ${completedConcepts.includes(key) ? 'completed' : ''}`}
          onClick={() => handleConceptSelect(key)}
        >
          <div className="concept-icon-section">
            {React.createElement(concept.icon, { className: "icon" })}
            {completedConcepts.includes(key) && (
              <div className="completion-badge">
                Completed! ✨
              </div>
            )}
          </div>
          <div className="concept-info">
            <h2 className="concept-title">{concept.title}</h2>
            <p className="concept-description">{concept.description}</p>
          </div>
        </div>
      ))}
    </div>
  );

  const renderContent = () => {
    const concept = aiConcepts[selectedConcept];
    const currentContent = concept.content[currentContentIndex];
    
    return (
      <div className="content-interface">
        <div className="content-section">
          <h3 className="section-title">{currentContent.title}</h3>
          <ul className="section-details">
            {currentContent.details.map((detail, idx) => (
              <li key={idx}>{detail}</li>
            ))}
          </ul>
          
          <div className="navigation-buttons">
            <button
              className="nav-button"
              onClick={handlePreviousContent}
              disabled={currentContentIndex === 0}
            >
              Previous
            </button>
            <div className="content-progress">
              {currentContentIndex + 1} / {concept.content.length}
            </div>
            <button
              className="nav-button next-button"
              onClick={handleNextContent}
            >
              {currentContentIndex < concept.content.length - 1 ? 'Next' : 'Start Game'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderGame = () => (
    <div className="game-interface">
      <div className="input-container">
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && userInput.trim() && handleCommand()}
          placeholder="Type your answer or 'hint'..."
          className="game-input"
        />
        <button
          onClick={handleCommand}
          disabled={!userInput.trim()}
          className="submit-button"
        >
          Submit
        </button>
      </div>

      <div className="messages-container">
        {messages.map((msg, idx) => (
          <div key={idx} className="message">{msg}</div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="ai-explorer">
      <div className="game-background">
        <div className="background-overlay" />
        {renderHeader()}
        <main className="game-content">
          {gamePhase === 'selection' && renderConceptSelection()}
          {gamePhase === 'content' && renderContent()}
          {gamePhase === 'game' && renderGame()}
          {gamePhase !== 'selection' && (
            <a 
              href="#" 
              onClick={(e) => {
                e.preventDefault();
                handleBackToSelection();
              }} 
              className="back-button"
            >
              Back to Concepts
            </a>
          )}
        </main>
      </div>
    </div>
  );
};

const aiConcepts = {
  perception: {
    title: "How I See the World! 👀",
    icon: Eye,
    theme: "theme-perception",
    description: "Hey there! Let me show you how I experience the world around us ✨",
    content: [
      {
        title: "My Digital Eyes 📸",
        details: [
          "Just like you use your eyes, I see the world through pixels - they're like tiny digital dots! When I look at a photo of your cat, I break it down into thousands of colorful points 🎨",
          "I'm like a digital detective! I can spot objects by looking for special patterns - just like how you know a cat by its pointy ears and whiskers! 🐱",
          "I can even recognize faces! It's like having a super-memory for remembering what everyone looks like 🤗",
          "When I look at a scene, I split it into parts - like seeing the sky, trees, and buildings in your vacation photos! 🌄"
        ]
      },
      {
        title: "My Listening Skills 🎵",
        details: [
          "When you talk to me, I hear sound waves that I turn into text - like having subtitles for everything! 🗣️",
          "I can understand different voices and accents by finding patterns in speech - it's like being a music lover who appreciates all kinds of tunes! 🎼",
          "I connect speech with text to understand multiple languages - I'm like a digital polyglot! 🌍",
          "I can even help with real-time translations, like having a friendly interpreter in your pocket! 🔄"
        ]
      }
    ],
    games: [
      {
        title: "Train My Vision! 👁️",
        description: "Help me learn to see patterns like you do!",
        challenge: "Hey friend! I'm learning to recognize shapes. Here's a sequence:\n🔳 ⬛ ⬜🔳 ⬛ \nWhat comes next in the pattern?",
        answer: "⬜",
        hint: "Look at how the shapes alternate!"
      }
    ]
  },
  learning: {
    title: "My Learning Journey! 🧠",
    icon: Brain,
    theme: "theme-learning",
    description: "Come explore how I learn and grow smarter every day! 📚",
    content: [
      {
        title: "Learning from Examples 📚",
        details: [
          "I learn just like you do - by seeing lots of examples! For instance, after seeing many 🐱 pictures🐱🐱🐱, I learn what makes a cat a cat!",
          "I organize information into categories - like sorting your photos into 'selfies', 'food pics', and 'vacation shots'!",
          "You're my teacher! The more examples you show me, the better I get at understanding things!",
          "I check my work to make sure I'm learning correctly - just like double-checking your homework!"
        ]
      },
      {
        title: "Finding Patterns 🔍",
        details: [
          "I'm like a pattern detective, finding connections that aren't obvious at first!",
          "I group similar things together, like organizing your music by mood or style",
          "Sometimes I find new ways to look at data, like discovering that your favorite songs have similar beats!",
          "I can spot unusual patterns too - like noticing when something doesn't fit the usual pattern!"
        ]
      }
    ],
    games: [
      {
        title: "Pattern Predictor",
        description: "Help me learn number patterns!",
        challenge: "I've learned this pattern:\n2 → 4\n3 → 6\n4 → 8\nWhat should 5 become?",
        answer: "10",
        hint: "What operation is being applied to each number?"
      }
    ]
  },
  language: {
    title: "Let's Chat! 💭",
    icon: MessageSquare,
    theme: "theme-language",
    description: "Discover how I understand and chat with you! 🗣️",
    content: [
      {
        title: "Understanding Words 🎯",
        details: [
          "I process words like pieces of a conversation puzzle - each word has its own special meaning!",
          "I can tell if a message is happy or sad by looking at the words - like reading your friend's mood!",
          "I understand language patterns, just like learning the rules of a new game!",
          "I can sort messages into different types, like organizing your emails into 'fun', 'work', or 'important'!"
        ]
      },
      {
        title: "Making Conversations 💬",
        details: [
          "I can write stories and messages, like a creative writing buddy!",
          "I help chatbots have friendly conversations - it's like being a digital pen pal!",
          "I can make up new stories using what I've learned - like building with LEGO blocks of words!",
          "I can even help translate between languages, like having a universal translator!"
        ]
      }
    ],
    games: [
      {
        title: "Complete the Pattern",
        description: "Help me understand language patterns!",
        challenge: "I'm learning word patterns:\nHappy → Sad\nGood → Bad\nUp → ?\nWhat's the opposite of 'Up'?",
        answer: "down",
        hint: "Think about opposites!"
      }
    ]
  }
};

export default AILearningGame;