import React, { useState, useEffect, useRef } from 'react';
import { Github, Linkedin, Mail, Youtube } from 'lucide-react';
import './portfoliosite.css';
import AILearningGame from './components/ai_learning_game/frontend/AILearningGame';
import BlogSection from './components/BlogSection';
import { Link, useNavigate } from 'react-router-dom';

const PortfolioSite = () => {
  const [activeSection, setActiveSection] = useState('home');
  const [expandedProject, setExpandedProject] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth >= 768 && window.innerWidth < 1024);
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const [imagesLoaded, setImagesLoaded] = useState({
    desktop: false,
    mobile: false
  });
  const navigate = useNavigate();
  
  // Reference to the background image for measurements
  const bgImageRef = useRef(null);

  // Preload images when component mounts
  useEffect(() => {
    const preloadImages = () => {
      // Preload desktop SVG
      const desktopImg = new Image();
      desktopImg.onload = () => {
        console.log("Desktop SVG loaded successfully");
        setImagesLoaded(prev => ({ ...prev, desktop: true }));
      };
      desktopImg.onerror = (error) => {
        console.error("Failed to load desktop SVG", error);
      };
      desktopImg.src = '/assets/website_desktop.svg';
      
      // Preload mobile SVG
      const mobileImg = new Image();
      mobileImg.onload = () => {
        console.log("Mobile SVG loaded successfully");
        setImagesLoaded(prev => ({ ...prev, mobile: true }));
      };
      mobileImg.onerror = (error) => {
        console.error("Failed to load mobile SVG", error);
      };
      mobileImg.src = '/assets/website_mobile.svg';
    };
    
    preloadImages();
  }, []);

  // Update when window resizes or orientation changes
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setIsTablet(window.innerWidth >= 768 && window.innerWidth < 1024);
      setIsPortrait(window.innerHeight > window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  // Navigation items
  const navItems = [
    { id: 'home', label: 'Home' },
    { id: 'about', label: 'About' },
    { id: 'projects', label: 'Projects' },
    { id: 'blog', label: 'Blog' }
  ];

  // Navigate to AI Explorer
  const goToAIExplorer = () => {
    console.log("Navigating to AI Explorer...");
    window.location.href = '/ai-game';
  };

  // Handle clicks on the projects page image
  const handleProjectImageClick = (e) => {
    if (!bgImageRef.current) return;
    
    // Get image dimensions
    const imgRect = bgImageRef.current.getBoundingClientRect();
    
    // Get the click coordinates relative to the container
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    // Convert to percentages of container dimensions
    const xPercent = (x / rect.width) * 100;
    const yPercent = (y / rect.height) * 100;
    
    console.log(`Clicked at x: ${xPercent.toFixed(1)}%, y: ${yPercent.toFixed(1)}%`);

    // Get viewport dimensions for responsive calculations
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    
    // Define clickable regions based on device size
    let isInClickableRegion = false;
    
    if (viewportWidth < 768) {
      // Mobile: center area
      isInClickableRegion = (
        xPercent >= 15 && xPercent <= 85 &&
        yPercent >= 20 && yPercent <= 60
      );
    } else if (viewportWidth < 1024) {
      // Tablet: adjusted for tablet dimensions
      isInClickableRegion = (
        xPercent >= 15 && xPercent <= 45 &&
        yPercent >= 15 && yPercent <= 45
      );
    } else {
      // Desktop: precise area for the AI Explorer frame
      isInClickableRegion = (
        xPercent >= 10 && xPercent <= 32 &&
        yPercent >= 11 && yPercent <= 42
      );
    }
    
    if (isInClickableRegion) {
      console.log("AI Explorer region clicked!");
      goToAIExplorer();
    }
  };

  // Render Header with Logo and Navigation
  const renderHeader = () => (
    <header className="site-header">
      <div className="logo-container">
        <img src="/assets/ejey.png" alt="Ej Logo" className="logo-image" />
        <h1 className="logo-text">Ej Shin</h1>
      </div>
      <nav className="main-nav">
        {navItems.map(({ id, label }) => (
          <button
            key={id}
            onClick={() => {
              setActiveSection(id);
              setExpandedProject(null);
            }}
            className={`nav-btn ${activeSection === id ? 'nav-btn-active' : ''}`}
          >
            {label}
          </button>
        ))}
      </nav>
      <div className="social-links">
        <a href="https://github.com" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
          <Github size={24} />
        </a>
        <a href="https://www.linkedin.com/in/eunjung-shin/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
          <Linkedin size={24} />
        </a>
      </div>
    </header>
  );

  // Render Home Page with Garden Illustration
  const renderHome = () => {
    const currentImageLoaded = isMobile 
      ? imagesLoaded.mobile 
      : imagesLoaded.desktop;
      
    const imageSrc = isMobile 
      ? '/assets/website_mobile.svg' 
      : '/assets/website_desktop.svg';
    
    return (
      <div className="home-container">
        {!currentImageLoaded && (
          <div className="image-placeholder">
            <div className="placeholder-loader"></div>
          </div>
        )}
        <img 
          ref={bgImageRef}
          src={imageSrc}
          alt="Garden illustration with cat on bench"
          className={`home-illustration ${currentImageLoaded ? 'loaded' : 'loading'}`}
          onLoad={() => {
            if (isMobile) {
              setImagesLoaded(prev => ({ ...prev, mobile: true }));
            } else {
              setImagesLoaded(prev => ({ ...prev, desktop: true }));
            }
          }}
          onError={(e) => {
            console.error(`Failed to load ${isMobile ? 'mobile' : 'desktop'} SVG`);
            // Try to reload the image with a cache-busting parameter
            setTimeout(() => {
              e.target.src = `${imageSrc}?reload=${new Date().getTime()}`;
            }, 1000);
          }}
        />
      </div>
    );
  };

  // Render About Page
  const renderAbout = () => (
    <div className="about-container">
      <h2 className="section-title">About Me ✏️</h2>
      <div className="about-content">
        <p>
          Hey, I'm EJ – an AWS DevOps engineer and illustrator living in North East England. I'm from South Korea and a mum of two. 
          I love creating fun and beautiful digital experiences, and I'm passionate about using AI to make things better for future generations.
        </p>
      </div>
    </div>
  );
  
  // Render Projects Page
  const renderProjects = () => (
    <div className="projects-container">
      <h2 className="section-title">My Projects 🚀</h2>
      
      <div className="project-card">
        <h3 className="project-title">AI Explorer</h3>
        <p className="project-description">
        A simple interactive platform where I tried to teach AI concepts using visuals. 
        This project shows my first steps in mixing education with technology.
        </p>
        <button 
          onClick={goToAIExplorer} 
          className="project-button"
        >
          Launch AI Explorer
        </button>
      </div>
    </div>
  );

  // Render Blog Section
  const renderBlog = () => (
    <div className="blog-container">
      <h2 className="section-title">Blog ✍️</h2>
      <BlogSection />
    </div>
  );

  // Render Content Based on Active Section
  const renderContent = () => {
    switch(activeSection) {
      case 'home':
        return renderHome();
      case 'about':
        return renderAbout();
      case 'projects':
        return renderProjects();
      case 'blog':
        return renderBlog();
      default:
        return renderHome();
    }
  };

  return (
    <div className="site-container">
      {renderHeader()}
      <main className="main-content">
        {renderContent()}
      </main>
    </div>
  );
};

export default PortfolioSite;