// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import PortfolioSite from './portfoliosite';
import AILearningGame from './components/ai_learning_game/frontend/AILearningGame';
import ResourceDisplay from './components/ResourceDisplay';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<PortfolioSite />} />
          <Route path="/ai-game" element={<AILearningGame />} /> 
          <Route path="/resources/react-basics" element={<ResourceDisplay />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;