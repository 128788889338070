// src/components/ResourceDisplay.js
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { reactBasics } from '../blog/resources';

const ResourceDisplay = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">{reactBasics.title}</h1>
      <div className="mb-4">
        <span className="text-gray-600">Difficulty: {reactBasics.difficulty}</span>
        <span className="ml-4 text-gray-600">Last Updated: {reactBasics.date}</span>
      </div>
      <div className="prose max-w-none">
        <ReactMarkdown>{reactBasics.content}</ReactMarkdown>
      </div>
    </div>
  );
};

export default ResourceDisplay;