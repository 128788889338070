import React from 'react';
import { blogPosts } from '../blog/posts';
import ReactMarkdown from 'react-markdown';

const BlogSection = () => {
  return (
    <div className="blog-section">
      {blogPosts.map((post) => (
        <article key={post.id} className="blog-post">
          <div className="prose">
            <h2 className="text-3xl font-bold mb-4 bg-gradient-to-r from-blue-400 to-indigo-400 bg-clip-text text-transparent">
              {post.title}
            </h2>
            <div className="text-gray-400 mb-4">{post.date}</div>
            <ReactMarkdown>{post.content}</ReactMarkdown>
          </div>
        </article>
      ))}
    </div>
  );
};

export default BlogSection;